import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const ServiceGrid = ({ gridItems }) => (
  <div>
    {gridItems.map(item => (
      <div key={item.text} style={{background: 'rgb(245,245,245)', boxShadow: '15px 15px 20px grey'}}>
        <section className="section">
          <h2>{item.title}</h2>
          <div className="has-text-centered">
            <div
              style={{
                width: '60%',
                display: 'inline-block',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
            <p>{item.text}</p>
        </section>
        <hr/>
      </div>
    ))}
  </div>
)

ServiceGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default ServiceGrid
