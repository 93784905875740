import React from 'react'
import PropTypes from 'prop-types'

const GetStarted = ({ data }) => (
  <div className="columns">
    {data.map(step => (
      <div key={step.step} className="column">
        <section className="section">
          <h2 className="is-size-1 has-text-weight-bold has-text-primary has-text-centered">
            {step.step}
          </h2>
          <h4 className="has-text-centered has-text-weight-semibold">
            {step.title}
          </h4>
          <p className="has-text-weight-semibold">{step.description}</p>
          
        </section>
      </div>
    ))}
  </div>
)

GetStarted.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default GetStarted
